import React from 'react'
import './skills.scss'
import img1 from './express.png'
import img2 from './Socket-io.svg'
import { Tooltip } from '@mui/material'
function Skills() {
    
    return (
        <div className='skills' id='skills'>    
            <h1>My Skills</h1>
                
            <div className='container'>
                <div className='row'>
                    <div className='col-3 text-center'>
                        <Tooltip title="HTML 5"><img className='icon-class' src="https://img.icons8.com/color/144/000000/html-5--v1.png" /></Tooltip><br />
                        <h5>HTML5</h5>
                    </div>
                    <div className='col-3 text-center'>
                        <Tooltip title="CSS 3"><img className='icon-class' src="https://img.icons8.com/color/144/000000/css3.png" /></Tooltip>
                        <br/><h5>CSS3</h5>
                    </div>
                    <div className='col-3 text-center'>
                        <Tooltip title="Bootstrap"><img className='icon-class' src="https://img.icons8.com/color/144/000000/bootstrap.png" /></Tooltip>
                        <br/><h5>Bootstrap</h5>
                    </div>
                    <div className='col-3 text-center'>
                        <Tooltip title="JavaScript"><img className='icon-class' src="https://img.icons8.com/color/144/000000/javascript--v1.png" /></Tooltip>
                        <br/><h5>JavaScript</h5>
                    </div>
                    <div className='col-3 text-center'>
                        <Tooltip title="ReactJS"><img className='icon-class' src="https://img.icons8.com/office/160/000000/react.png" /></Tooltip>
                        <br/><h5>ReactJS</h5>
                    </div>
                    <div className='col-3 text-center'>
                        <Tooltip title="MongoDB"><img className='icon-class' src="https://img.icons8.com/color/144/000000/mongodb.png" /></Tooltip>
                        <br/><h5>MongoDB</h5>
                    </div>
                    <div className='col-3 text-center'>
                        <Tooltip title="Node.js"><img className='icon-class' src="https://img.icons8.com/color/144/000000/nodejs.png" /></Tooltip>
                        <br/><h5>Node.js</h5>
                    </div>
                    <div className='col-3 text-center'>
                        <Tooltip title="ExpressJS"><img className='icon-class' src={img1} /></Tooltip>
                        <br/><h5>ExpressJS</h5>
                    </div>
                    <div className='col-3 text-center'>
                        <Tooltip title="Material UI"><img className='icon-class' src="https://img.icons8.com/color/144/000000/material-ui.png" /></Tooltip><br />
                        <h5>Material UI</h5>
                    </div>
                    <div className='col-3 text-center'>
                        <Tooltip title="socketio"><img className='icon-class' src={img2} /></Tooltip>
                        <br/><h5>Socket.io</h5>
                    </div>
                    <div className='col-3 text-center'>
                        <Tooltip title="Sass"><img className='icon-class' src="https://img.icons8.com/color/144/000000/sass.png" /></Tooltip>
                        <br/><h5>Sass/Scss</h5>
                    </div>
                    <div className='col-3 text-center'>
                        <Tooltip title="git"><img className='icon-class' src="https://img.icons8.com/color/144/000000/git.png"/></Tooltip>
                        <br/><h5>git</h5>
                    </div> 
                    </div>
                    {/* <div >
                        <ul >
                        <li className='icon'><Tooltip title="HTML 5"><img className='icon-class' src="https://img.icons8.com/color/144/000000/html-5--v1.png"/></Tooltip></li>
                        <li className='icon'><Tooltip title="CSS 3"><img className='icon-class' src="https://img.icons8.com/color/144/000000/css3.png"/></Tooltip></li>
                        <li className='icon'><Tooltip title="Bootstrap"><img className='icon-class' src="https://img.icons8.com/color/144/000000/bootstrap.png"/></Tooltip></li>
                        <li className='icon'><Tooltip title="JavaScript"><img className='icon-class' src="https://img.icons8.com/color/144/000000/javascript--v1.png"/></Tooltip></li>
                        <li className='icon'><Tooltip title="ReactJS"><img className='icon-class' src="https://img.icons8.com/office/160/000000/react.png"/></Tooltip></li>
                        <li className='icon'><Tooltip title="MongoDB"><img className='icon-class' src="https://img.icons8.com/color/144/000000/mongodb.png"/></Tooltip></li>
                        <li className='icon'><Tooltip title="Node.js"><img className='icon-class' src="https://img.icons8.com/color/144/000000/nodejs.png"/></Tooltip></li>
                        <li className='icon'><Tooltip title="ExpressJS"><img className='icon-class' src={img1}/></Tooltip></li>
                        <li className='icon'><Tooltip title="Material UI"><img className='icon-class' src="https://img.icons8.com/color/144/000000/material-ui.png"/></Tooltip></li>
                        <li className='icon'><Tooltip title="socketio"><img className='icon-class' src={img2}  /></Tooltip></li>
                        <li className='icon'><Tooltip title="Sass"><img className='icon-class' src="https://img.icons8.com/color/144/000000/sass.png"/></Tooltip></li>
                        </ul>
                        
                    </div> */}
                </div>
                
            
        </div>
    )
}

export default Skills
