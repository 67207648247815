import { Mail, Person } from '@mui/icons-material';
import GithubIcon from '@mui/icons-material/GitHub'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {TextField} from '@mui/material'
import {TextareaAutosize} from '@mui/base';
import React, { useState } from 'react'
import axios from 'axios'
import './contact.scss'
function Contact() {
    const [message, setMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState("")
    const [email,setEmail] = useState("")
    const handleSubmit = async (e) => {
      setMessage(false)
      setLoading(true)
      e.preventDefault();
      const {data} = await axios.post(`https://recipe-management-gopi.herokuapp.com/contact`,{email,text})
      setMessage(true);
      setLoading(false)
      console.log(data.message)
      // alert(data.message)
  };
    return (
        <div className="contact" id="contact">
      <div className="left">
        <h1>Connect with me!</h1>
                <h2>Thank you for taking your time on visiting this page. You can contact me via. the following means</h2>
          <div className='cont'>
            
            <div className="itemContainer">
              <Person className="icon" />
              <span>+91 9491 960 727</span>
          </div>
          <div className="itemContainer">
              <Mail className="icon" />
              <span>kishorepenjendra@gmail.com</span>
            </div>
            <div className="itemContainer">
              <GithubIcon className="icon" />
              <span><a href="https://github.com/gopikish1998" target={ "_blank"}>gopikish1998</a></span>
            </div>
            <div className="itemContainer">
              <LinkedInIcon className="icon" />
              <span><a href="https://www.linkedin.com/in/gopi-kishore-penumaka-a031a3182/" target={"_blank"}>Gopi Kishore Penumaka</a></span>
            </div>
        </div>

       </div>
      <div className="right">
        <h2>Contact</h2>
        <form onSubmit={(e) =>handleSubmit(e)}>
          <TextField type="email" required sx={{width:'100%',bgcolor:'white', borderRadius:"10px", height:'50px'}}  variant="filled" label="Email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
          <TextField sx={{margin:'10%',bgcolor:'white',width:'100%', borderRadius:"10px"}} required multiline variant="filled" label="Message" minRows={5} maxRows={10} value={text} onChange={(e)=>setText(e.target.value)}></TextField>
          <button type="submit" disabled={loading}>{loading?<>sending...</>:<>Send</>}</button>
          {message && <span>Thanks, I'll reply ASAP :)</span>}
        </form>
      </div>
    </div>
    )
}

export default Contact
