import { IconButton } from '@mui/material';
import {useWindowScroll} from 'react-use'
import React, { useEffect, useState } from 'react';
import './scroll.scss'
function Scroll() {
    const [show, setShow] = useState(true)
    const {y:pageYOffset} = useWindowScroll()
    useEffect(() => {
        // document.getElementById('sections').scrollTop = 100;
        console.log(document.activeElement)
        // console.log(pageYOffset)
        if (pageYOffset > 300) {
            setShow(true)
        }
        else { 
            setShow(false)
        }
    });
    
    return ( 
        <div className='scroll'>
           {show&& <a href='#intro'><img src='assets/up.svg'/></a>}
      </div>
  )
}

export default Scroll;
