import logo from './logo.svg';
import './app.scss';
import img from "./profile.png";
import Portfolio from './Portfolio';
import Topbar from './components/Topbar';
import { useState } from 'react';
import Menu from './components/Menu';
import Intro from './components/Intro';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Education from './components/Education';
import Interests from './components/Interests';
import Contact from './components/Contact';
import Scroll from './components/Scroll';
function App() {
  const [menuOpen,setMenuOpen] = useState(false)

  return (
    <div className="app">
      <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className="sections" id='sections'>
        <Intro />
        <Skills />
        <Projects/>
        <Education />
        {/* <Interests /> */}
        <Contact />
      </div> <Scroll/>
      </div>
    
  );
}

export default App;
