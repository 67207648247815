import React from 'react'
import './interests.scss'
function Interests() {

    return (
        <div className='interests' id='interests'>
            <h1>Interests</h1>
            {/* <div className='container'>
                <div className='card'>
                    <h2>Practicing Data Structures and Algorithms</h2>
                </div>
                <div className='card'>
                    <h2>Learn about more CSS libraries</h2>
                </div>
                <div className='card'>
                    <h2>Reading books</h2>
                </div>
            </div> */}
            
        </div>
    )
}

export default Interests
