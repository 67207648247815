import React from 'react'
import './education.scss'
function Education() {
    return (
        <div className="education" id="education">
      <h1>Education & Certifications</h1>
      <div className="container1">
          <div className="card1">
            <div className="bottom1">
              <h3>Bachelor of Technology</h3>
              <h4>Indian Institute of Technology Gandhinagar<h4>2016-2020</h4></h4>
                </div>
                <div className="center1">
              CPI : 6.14
            </div>
          </div>
          <div className="card1">
            <div className="bottom1">
              <h3>Intermediate Education</h3>
              <h4>Vignan Junior College<h4>2014-2016</h4></h4>
                </div>
                <div className="center1">
              Percentage : 98.7%
            </div>
          </div>
          <div className="card1">
            <div className="bottom1">
              <h3>Seconday School </h3>
              <h4>Sri Aurobindo Integral High  School<h4>2013-2014</h4></h4>
                </div>
                <div className="center1">
              GPA : 9.5
            </div>
          </div>
          <div className="card1">
            <div className="bottom1">
              <h3>Full Stack Development</h3>
              <h4>Zen Class by GUVI<h4>June 2020- Oct 2020</h4></h4>
                </div> 
          </div>
      </div>
    </div>
    )
}

export default Education
