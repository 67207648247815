import "./intro.scss";
// import Typical from "react-typical"
import { init } from "ityped";
import { useEffect, useRef } from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import { Button, Tooltip } from "@mui/material";
import img from './dev.png'

export default function Intro() {
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      backDelay: 1500,
      backSpeed:60,
      strings: ["Full Stack Developer","MERN Stack Developer", "Frontend Developer", "Backend Developer"],
    });
  }, []);

  return (
      <div className="intro" id="intro">
      <div className="right">
        <div className="wrapper">
          <h2>Hey there, I'm</h2>
          <h1>Gopi Kishore</h1>
          <h3 >
            <span ref={textRef}></span>
          </h3>
          <p>I am a graduate from IIT Gandhinagar. I like to work on MERN Stack. I have a handful practice and Experience with the projects covering most full stack concepts such as RESTful API, Frontend, Backend and Database management etc.</p>
          <div className="connect">
              <Tooltip title={'Github'}><a href="https://github.com/gopikish1998" target={"_blank"}><i className="fab fa-github fa-2x"></i></a></Tooltip>
              <Tooltip title={'Resume'}><a href='https://drive.google.com/file/d/1JZqAT2Fgo_X2o_jkxdClAp14MZ_Xgmfi/view' target='_blank'><i class="fas fa-download fa-2x"></i></a></Tooltip>
              <Tooltip title={'LinkedIn'}><a href="https://www.linkedin.com/in/gopi-kishore-penumaka-a031a3182/" target={"_blank"}><i className="fab fa-linkedin fa-2x"></i></a></Tooltip>
          </div>
          <div className="connect">
            <a className='button1' href='#contact'>Contact Me</a>
          </div>
        </div>
        
        <a href="#skills">
          <img src="assets/down1.svg" alt="" />
        </a>
      </div>
      <div className="left">
        <div className="imgContainer">
          {/* <Tooltip title={'Github'}><a href="https://github.com/gopikish1998" target={"_blank"}><i className="fab fa-github fa-3x"  ></i></a></Tooltip>
          <Tooltip title={'Resume'}><a href='https://drive.google.com/uc?export=download&id=1JZqAT2Fgo_X2o_jkxdClAp14MZ_Xgmfi' target='_blank' download='Gopi_Kishore.pdf'><i class="fas fa-download fa-3x"></i></a></Tooltip>
          <Tooltip title={'LinkedIn'}><a href="https://www.linkedin.com/in/gopi-kishore-penumaka-a031a3182/" target={"_blank"}><i className="fab fa-linkedin fa-3x" ></i></a></Tooltip>
           */}
          <img src={img} alt='How you doing'/>
        </div>
      </div>
          
          
    </div>
  );
}
