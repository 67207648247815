import React from 'react'
import './projects.scss'
import img1 from './chat.jpg'
import img2 from './bms.png'
import img3 from './recipe.jpg'
import img4 from './diary.jpeg'
import { Box, Tooltip } from '@mui/material'
function Projects() {
    return (
        <div className='portfolio' id='projects'>
            <h1>Projects</h1>
            <div className='container'>
                <div className="item">
                    <img
                    src={img1}
                    alt=""
                    />
                    <h3>Chat Application</h3>
                    <p>Implemented Chat Application using MERN Stack and Socketio. Used Material UI for styling. Project includes user login, group and individal chat, view profiles etc.
                     <br/> <span>  Sample Credentials: testuser@example.com 
                     <br/>   Password: 1111</span>
                    </p>
                    
                    <div className='stack' >
                        <Box className='stack-item1'>
                            ReactJS
                        </Box>
                        <Box className='stack-item2'>
                            NodeJS
                        </Box>
                        <Box className='stack-item3'>
                            ExpressJS
                        </Box>
                        <Box className='stack-item2'>
                            MongoDB
                        </Box>
                        <Box className='stack-item1'>
                            MaterialUI
                        </Box>
                        <Box className='stack-item3'>
                            Socket.io
                        </Box>
                    </div>
                    
                    <div className='link'>
                        <Tooltip title="FrontEnd Source"><a href="https://github.com/gopikish1998/socketio_chat" target={"_blank"}><i className='fab fa-react fa-2x'></i></a></Tooltip>
                        <Tooltip title="BackEnd Source"><a href="https://github.com/gopikish1998/SOCKET" target={"_blank"}><i className='fab fa-node-js fa-2x'></i></a></Tooltip>
                        <Tooltip title="View Project"><a href="https://socketiochat-gopi.netlify.app" target={"_blank"}><i className='fas fa-link fa-2x'></i></a></Tooltip>
                    </div>
                </div>
                <div className="item">
                    <img
                    src={img2}
                    alt=""
                    />
                    <h3>Movie Ticket Booking</h3>
                    <p>Implemented Movie ticketing application for both customers and theatre management. Key features includes user authentication, seat selection and test payment gateway from Razorpay.<br/> <span> Test Credentials: testuser@example.com (for customer), testadmin@example.com (for theatre management)
                     <br/>Password: 1111 (for both)</span></p>
                    
                    <div className='stack' >
                        <Box className='stack-item1'>
                            React JS
                        </Box>
                        <Box className='stack-item2'>
                            NodeJS
                        </Box>
                        <Box className='stack-item3'>
                            ExpressJS
                        </Box>
                        <Box className='stack-item2'>
                            MongoDB
                        </Box>
                        <Box className='stack-item'>
                            Bootstrap
                        </Box>
                    </div>
                    <div className='link'>
                        <Tooltip title="FrontEnd Source"><a href="https://github.com/gopikish1998/guvi-hackathon2-frontend" target={"_blank"}><i className='fab fa-react fa-2x'></i></a></Tooltip>
                        <Tooltip title="BackEnd Source"><a href="https://github.com/gopikish1998/guvi-hackathon2-backend" target={"_blank"}><i className='fab fa-node-js fa-2x'></i></a></Tooltip>
                        <Tooltip title="View Project"><a href="https://guvi-hackathon2-gopi.netlify.app/" target={"_blank"}><i className='fas fa-link fa-2x'></i></a></Tooltip>
                    </div>
                </div>
                <div className="item">
                    <img
                    src={img3}
                    alt=""
                    />
                    <h3>Kitchen Recipe Management</h3>
                    <p>Built a Kitchen recipe management application using MERN Stack. This project includes user authentication, groceries list management, users can also browse for recipes and can contribute by adding their recipes to the catalog.<br/> <span>  Sample Credentials: testuser@example.com 
                     <br/>   Password: 1111</span></p>
                    
                    <div className='stack' >
                        <Box className='stack-item1'>
                            React JS
                        </Box>
                        <Box className='stack-item2'>
                            NodeJS
                        </Box>
                        <Box className='stack-item3'>
                            ExpressJS
                        </Box>
                        <Box className='stack-item2'>
                            MongoDB
                        </Box>
                        <Box className='stack-item'>
                            Bootstrap
                        </Box>
                    </div>
                    <div className='link'>
                        <Tooltip title="FrontEnd Source"><a href="https://github.com/gopikish1998/capStoneProject-Frontend" target={"_blank"}><i className='fab fa-react fa-2x'></i></a></Tooltip>
                        <Tooltip title="BackEnd Source"><a href="https://github.com/gopikish1998/capStoneProject-Backend" target={"_blank"}><i className='fab fa-node-js fa-2x'></i></a></Tooltip>
                        <Tooltip title="View Project"><a href="https://recipe-management-gopi.netlify.app" target={"_blank"}><i className='fas fa-link fa-2x'></i></a></Tooltip>
                    </div>
                </div>
                <div className="item">
                    <img
                    src={img4}
                    alt=""
                    />
                    <h3>Diary Organizer</h3>
                    <p>Built a diary management application using MERN stack. This involves user authentication, daily notes management with Formatting options and can also manage events. Used Material UI for styling.<br/> <span>  Sample Credentials: testuser@example.com 
                     <br/>   Password: 1111</span></p>
                    
                    <div className='stack' >
                        <Box className='stack-item1'>
                            ReactJS
                        </Box>
                        <Box className='stack-item2'>
                            NodeJS
                        </Box>
                        <Box className='stack-item3'>
                            ExpressJS
                        </Box>
                        <Box className='stack-item2'>
                            MongoDB
                        </Box>
                        <Box className='stack-item'>
                            Bootstrap
                        </Box>
                        <Box className='stack-item1' >
                            MaterialUI
                        </Box>
                    </div>
                    <div className='link'>
                        <Tooltip title="FrontEnd Source"><a href="https://github.com/gopikish1998/diary-organizer" target={"_blank"}><i className='fab fa-react fa-2x'></i></a></Tooltip>
                        <Tooltip title="BackEnd Source"><a href="https://github.com/gopikish1998/diary-organizer-backend" target={"_blank"}><i className='fab fa-node-js fa-2x'></i></a></Tooltip>
                        <Tooltip title="View Project"><a href="https://diary-organizer.netlify.app" target={"_blank"}><i className='fas fa-link fa-2x'></i></a></Tooltip>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects
 